define("discourse/plugins/discourse-ratings/discourse/components/rating-type", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const minTypeLength = 2;
  const minNameLength = 2;
  const noneType = "none";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("invalidName", "type.name", "currentName"), (_obj = {
    tagName: "tr",
    classNameBindings: [":rating-type", ":admin-ratings-list-object", "hasError"],
    invalidType: (0, _computed.lt)("type.type.length", minTypeLength),
    invalidName: (0, _computed.lt)("type.name.length", minNameLength),
    addDisabled: (0, _computed.or)("invalidType", "invalidName"),
    noneType: (0, _computed.equal)("type.type", noneType),
    showControls: (0, _computed.not)("noneType"),
    didReceiveAttrs() {
      this.set("currentName", this.type.name);
    },
    updateDisabled(invalidName, name, currentName) {
      return invalidName || name === currentName;
    }
  }, (_applyDecoratedDescriptor(_obj, "updateDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateDisabled"), _obj)), _obj)));
});