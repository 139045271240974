define("discourse/plugins/discourse-ratings/discourse/components/rating-migrate", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating", "@ember/component"], function (_exports, _decorators, _rating, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const noneType = "none";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("categoryId", "toType", "fromType"), (_obj = {
    classNames: ["rating-action-controls"],
    migrateDisabled(categoryId, toType, fromType) {
      return [categoryId, toType, fromType].any(i => !i) || toType !== noneType && fromType !== noneType;
    },
    actions: {
      migrate() {
        let data = {
          category_id: this.categoryId,
          type: this.fromType,
          new_type: this.toType
        };
        this.set("startingMigration", true);
        _rating.default.migrate(data).then(result => {
          if (result.success) {
            this.set("migrationMessage", "admin.ratings.migrate.started");
          } else {
            this.set("migrationMessage", "admin.ratings.error.migration_failed_to_start");
          }
        }).finally(() => this.set("startingMigration", false));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "migrateDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "migrateDisabled"), _obj)), _obj)));
});