define("discourse/plugins/discourse-ratings/discourse/components/rating-star", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/component"], function (_exports, _decorators, _computed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("rating"), (_obj = {
    tagName: "input",
    disabled: (0, _computed.not)("enabled"),
    attributeBindings: ["value", "checked:checked", "disabled:disabled"],
    willInsertElement() {
      this.element.type = "radio";
    },
    didRender() {
      // For IE support
      this.element.value = this.value;
    },
    click() {
      this.set("rating", this.element.value);
    },
    checked(rating) {
      return this.get("value") <= rating;
    }
  }, (_applyDecoratedDescriptor(_obj, "checked", [_dec], Object.getOwnPropertyDescriptor(_obj, "checked"), _obj)), _obj)));
});