define("discourse/plugins/discourse-ratings/discourse/components/rating-destroy", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating", "@ember/component"], function (_exports, _decorators, _rating, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("categoryId", "type"), (_obj = {
    classNames: ["admin-ratings-destroy", "rating-action"],
    destroyDisabled(categoryId, type) {
      return [categoryId, type].any(i => !i);
    },
    actions: {
      destroyRatings() {
        let data = {
          category_id: this.categoryId
        };
        this.set("startingDestroy", true);
        _rating.default.destroy(this.type, data).then(result => {
          if (result.success) {
            this.set("destroyMessage", "admin.ratings.destroy.started");
          } else {
            this.set("destroyMessage", "admin.ratings.error.destroy_failed_to_start");
          }
        }).finally(() => this.set("startingDestroy", false));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "destroyDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "destroyDisabled"), _obj)), _obj)));
});